<template>
  <div class="spent">
    <div class="title-section">
      <h1 class="title">
        Spent
      </h1>

      <div class="spent-filters">
        <export :grid-api="api || undefined" />
      </div>
    </div>

    <filters
      :variant-options="variantOptions"
      :variant-options2="variantOptions2"
    />

    <section>
      <grid
        :columns="columns"
        :data="spent"
        :loading="$apollo.loading"
        :auto-group-column="autoGroupColumnDef"
        @ready="api = $event"
      />
    </section>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import countries from '../data/countries';
import { FILTERS } from '../plugins/filters';
import { GET_SPENT } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/spent/Export';

export default {
  components: { Export },
  mixins: [GridMixin],
  data() {
    return {
      variantOptions: {
        title: 'Breakdown',
        list: [{
          key: 'channel',
          name: 'Channel',
        }, {
          key: 'country',
          name: 'Country',
        }, {
          key: 'lunigroup',
          name: 'Luni Group',
        }],
      },
      variantOptions2: {
        title: 'Row group',
        key: 'spent-group',
        list: [
          {
            key: 'app',
            name: 'Apps',
          },
          {
            key: 'breakdown',
            name: 'Breakdown',
          },
        ],
      },
      appNames: {},
      spentByApp: [],
      spentObject: {},
      countries,
      api: null,
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getGranularity', 'getVariant', 'getVariant2']),
    autoGroupColumnDef() {
      return {
        headerName: this.headerName,
        minWidth: 300,
        field: this.autoGroupColumnDefField,
        pinned: 'left',
      };
    },
    headerName() {
      const breakdown = `${this.getVariant && this.getVariant[0].toUpperCase() + this.getVariant.slice(1)}`;

      return this.isRowGroupApps ? `App > ${breakdown}` : `${breakdown} > App`;
    },
    autoGroupColumnDefField() {
      if (!this.isRowGroupApps) {
        return 'appName';
      }
      return this.isVariantChannel ? 'channelName' : 'country';
    },
    isRowGroupApps() {
      return this.getVariant2['spent-group'] !== 'breakdown';
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantChannel() {
      return this.getVariant === 'channel';
    },
    isVariantLuniGroup() {
      return this.getVariant === 'lunigroup';
    },
    timePeriods() {
      const timePeriods = [];
      const timePeriod = moment(this.getDateRange[0]);

      if (this.getGranularity === 'weekly') {
        timePeriod.date(1).day(8);
        if (timePeriod.date() > 7) {
          timePeriod.day(-6);
        }
      }

      const end = moment(this.getDateRange[1]).endOf('month');
      const fieldFormat = this.getGranularity === 'weekly' ? 'YYYYWW' : 'YYYYMM';
      const labelFormat = this.getGranularity === 'weekly' ? 'DD MMM YYYY' : 'MMMM YYYY';
      while (timePeriod.isSameOrBefore(end)) {
        timePeriods.push({
          field: timePeriod.format(fieldFormat),
          label: timePeriod.format(labelFormat),
        });
        const period = this.getGranularity === 'weekly' ? 'week' : 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    columns() {
      const columns = [{
        field: 'appName',
        colId: 0,
        headerName: 'App',
        rowGroup: this.isRowGroupApps,
        hide: true,
      }];

      columns.push({
        field: this.isVariantChannel ? 'channelName' : 'country',
        colId: 1,
        headerName: this.isVariantChannel ? 'Channel' : 'Country',
        filter: true,
        sortable: true,
        pinned: 'left',
        maxWidth: 150,
        filterParams: {
          buttons: ['reset', 'apply'],
          debounceMs: 200,
        },
        menuTabs: ['generalMenuTab', 'filterMenuTab'],
        rowGroup: !this.isRowGroupApps,
        hide: true,
      });

      this.timePeriods.forEach((period) => {
        columns.push({
          field: period.field,
          colId: Number(period.field),
          headerName: period.label,
          sortable: true,
          valueGetter: (params) => {
            const currentAmount = params.data?.[params.colDef.field];
            const previousAmount = params.data?.[Number(params.colDef.field) - 1];
            const growth = previousAmount ? (currentAmount - previousAmount) / previousAmount : undefined;
            return { amount: currentAmount, previousAmount, growth };
          },
          aggFunc: this.mySum,
          filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
          },
          maxWidth: 170,
          menuTabs: ['generalMenuTab'],
          cellRendererFramework: 'AmountWithGrowth',
        });
      });

      columns.push({
        headerName: 'Total',
        field: 'total',
        aggFunc: 'sum',
        valueFormatter: this.totalFormatter,
        maxWidth: 130,
        sortable: true,
        pinned: 'right',
        cellClass: 'text-right',
        sort: 'desc',
        filterParams: {
          buttons: ['reset', 'apply'],
          debounceMs: 200,
        },
        menuTabs: ['generalMenuTab', 'filterMenuTab'],
      });

      return columns;
    },
    spent() {
      const spent = [];
      Object.keys(this.spentObject).forEach((appId) => {
        Object.keys(this.spentObject[appId]).forEach((dim) => {
          const row = {
            appName: this.appNames[appId],
          };

          if (this.isVariantChannel) {
            row.channelName = dim;
          } else {
            row.country = dim;
          }

          let total = 0;
          this.timePeriods.forEach((period) => {
            if (this.spentObject[appId][dim][period.field] === undefined) {
              row[period.field] = undefined;
            }
            row[period.field] = this.spentObject[appId][dim][period.field];
            total += this.spentObject[appId][dim][period.field] ? this.spentObject[appId][dim][period.field] : 0;
          });
          row.total = total;

          spent.push(row);
        });
      });
      return spent;
    },
  },
  watch: {
    spentByApp() {
      this.formatSpentAgGrid();
    },
  },
  methods: {
    totalFormatter(params) {
      return params.value ? `${FILTERS.formatNumber(params.value)} €` : '';
    },
    mySum(params) {
      let sum = 0;
      let sumPast = 0;
      params.values.forEach((value) => {
        sum += value?.amount || 0;
        sumPast += value?.previousAmount || 0;
      });
      const growth = sumPast ? (sum - sumPast) / sumPast : undefined;
      return { amount: sum, growth };
    },
    formatSpentAgGrid() {
      this.spentObject = {};

      this.spentByApp.forEach((raw) => {
        const timePeriod = this.getGranularity === 'weekly'
          ? `${raw.year}${this.withLeadingZero(raw.weekOfYear)}`
          : `${raw.year}${this.withLeadingZero(raw.month)}`;
        if (this.spentObject[raw.appId] === undefined) {
          this.spentObject[raw.appId] = {};
        }
        if (this.isVariantChannel) {
          if (this.spentObject[raw.appId][raw.channelName] === undefined) {
            this.spentObject[raw.appId][raw.channelName] = {};
          }
          this.spentObject[raw.appId][raw.channelName][timePeriod] = raw.amount;
        } else {
          if (this.spentObject[raw.appId][raw.country] === undefined) {
            this.spentObject[raw.appId][raw.country] = {};
          }
          this.spentObject[raw.appId][raw.country][timePeriod] = raw.amount;
        }

        if (this.appNames[raw.appId] === undefined) {
          this.appNames[raw.appId] = raw.appName;
        }
      });
    },
  },
  apollo: {
    spentByApp: {
      query: GET_SPENT,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        const variables = {
          weekly: this.getGranularity === 'weekly',
          variant: this.getVariant,
          isVariantCountry: this.isVariantCountry || this.isVariantLuniGroup,
          isVariantChannel: this.isVariantChannel,
        };
        if (this.getDateRange.length) {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          variables.from = `${from.getFullYear()}-${from.getMonth() + 1}`;
          variables.to = `${to.getFullYear()}-${to.getMonth() + 1}`;
        }
        return variables;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.spent-filters {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
